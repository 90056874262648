<script setup lang='ts'>
import { checkPhone } from '@qctsw/utils'
import { LOAD_STATE } from '~/shared/types'
import { getVerificationCodeApi } from '@/server'

const props = withDefaults(defineProps<{
  phone: string
}>(), {})

const { verificationCodeText, verificationCodeStatus, verificationCodeStart } = useVerificationCode()
const loadVerificationCodeState = ref(LOAD_STATE.INIT)
const disabled = computed(() => {
  return !checkPhone(props.phone)
    || verificationCodeStatus.value === 'change'
    || loadVerificationCodeState.value === LOAD_STATE.LOADING
})

async function getPhoneCode(phone: string) {
  if (!checkPhone(phone))
    return useMessage.error('请输入正确的手机号')

  const res = await useLoadState({
    state: loadVerificationCodeState,
    fn: () => getVerificationCodeApi(phone),
  })

  if (loadVerificationCodeState.value === LOAD_STATE.END || res?.code === 200) {
    useMessage.success('验证码已发送')
    verificationCodeStart()
  }
}
</script>

<template>
  <button
    :disabled="disabled"
    :class="[disabled ? 'cursor-not-allowed bg-blue-3' : 'cursor-pointer']"
    @click="getPhoneCode(props.phone)"
  >
    <i v-if="loadVerificationCodeState === LOAD_STATE.LOADING" class="i-eos-icons:loading mr-1 vertical-icon" />
    <span v-else>{{ verificationCodeText }}</span>
  </button>
</template>

<style lang='scss' scoped>

</style>
