/**
 * 倒计时
 */
interface VerificationCodeOptions {
  seconds?: number // 倒计时总秒数
  startText?: string // 获取验证码
  endText?: string // 倒计时结束时的提示
  changeText?: string // 正在倒计时中的提示 替换字符串"x|X"
}
type VerificationCodeStatus = 'start' | 'change' | 'end'

const defaultOptions = {
  seconds: 60, // 倒计时总秒数
  startText: '获取验证码', // 获取验证码
  endText: '重新获取', // 倒计时结束时的提示
  changeText: 'Xs', // 正在倒计时中的提示
}
export function useVerificationCode(useOptions?: VerificationCodeOptions) {
  const options = Object.assign(useOptions ?? {}, defaultOptions)
  const status = ref<VerificationCodeStatus>('start')
  const seconds = ref<number>(options.seconds)
  const text = ref<string>(options.startText)
  const timer = ref<any>(null)

  // 状态切换
  function statusChange(newStatus: VerificationCodeStatus = 'start') {
    status.value = newStatus
    textChange()
  }
  // 提示信息切换
  function textChange(currentStatus: VerificationCodeStatus = status.value) {
    switch (currentStatus) {
      case 'change':
        text.value = options[`${currentStatus}Text`].replace(/x|X/, seconds.value.toString())
        break
      default:
        text.value = options[`${currentStatus}Text`]
        break
    }
  }
  function start() {
    // 状态切换 -> 保存状态 -> 倒计时 -> 倒计时结束 -> 切换状态 -> 改变提示信息
    statusChange('change')
    // 倒计时
    timer.value = setInterval(() => {
      seconds.value = seconds.value - 1
      textChange()
      if (seconds.value <= 0) {
        reset()
        statusChange('end')
      }
    }, 1000)
  }
  // 重置当前状态
  function reset() {
    status.value = 'start'
    seconds.value = options.seconds
    text.value = options.startText
    clearInterval(timer.value)
    timer.value = null
  }

  return {
    verificationCodeText: text,
    verificationCodeStatus: status,
    verificationCodeStart: start,
    verificationCodeReset: reset,
  }
}
